<template>
	<div class="contentContainer">
		<LoadingScreen :loadingStatus="dataLoaded" />
		<GradientBackground :level="stationData?.uaqi?.main"/>
		<div class="content">
			<div class="topControls">
				<div>
					<button class="btn" @click="router.go(-1)">
						<FontAwesomeIcon :icon="faAngleLeft" />
						<span>Назад</span>
					</button>
				</div>
				<div class="navigationControls">
					<button class="btn" @click="goToSection(sectionRealtime)">
						<FontAwesomeIcon :icon="faCircleDot" :fade="true" />
						<span>Поточні дані</span>
					</button>
					<button class="btn" @click="goToSection(sectionStatistics)">
						<FontAwesomeIcon :icon="faChartLine" />
						<span>Статистика</span>
					</button>
					<button class="btn" @click="goToSection(sectionArchives)">
						<FontAwesomeIcon :icon="faFileZipper" />
						<span>Архіви</span>
					</button>
				</div>
			</div>
			<br />
			
			<h1>
				<FontAwesomeIcon :icon="getStationIcon(stationData)" />
				{{ stationData?.name }}
			</h1>
			<p>Ваша станція моніторингу якості повітря</p>
			<br/>
			<div class="row">
				<div class="col-xs-12 col-sm-6">
					<h2>Налаштування станції</h2>
					<small>Властивості, які ви можете змінити</small>
					<br/><br/>
					<div>
						<button v-if="!editStation" class="btn" @click="editStation = true">
							Редагувати 
							<FontAwesomeIcon :icon="faPen"/>
						</button>
						<button v-else class="btn" @click="onStationSaveClick()">
							Зберегти 
							<FontAwesomeIcon :icon="faFloppyDisk"/>
						</button>
					</div>
					<br/>
					<div v-if="stationData != null" class="stationData" ref="stationDataElement">
						<small>Назва станції: </small>
						<input 
							type="text" 
							class="text-box" 
							style="margin-bottom: 0.5em;"
							:class="{ invalid: !isStationNameValid(stationData.name) }"
							placeholder="Введіть назву"
							v-model="stationData.name"
						>

						<small>Тип встановлення</small><br/>
						<div style="margin-top: 0.2em; margin-bottom: 0.5em;">
							<button 
								class="btn" 
								:class="{ active: stationData.type == 'outdoor' }"
								style="margin-right: 0.25em;"
								@click="stationData.type = 'outdoor'"
							>
								Зовнішня
								<FontAwesomeIcon :icon="faTreeCity"/>
							</button>
							<button 
								class="btn" 
								:class="{ active: stationData.type == 'indoor' }"
								style="margin-right: 0.25em;"
								@click="stationData.type = 'indoor'"
							>
								Внутрішня
								<FontAwesomeIcon :icon="faHouseChimney"/>
							</button>
							<button 
								class="btn" 
								:class="{ active: stationData.type == 'portable' }"
								@click="stationData.type = 'portable'"
							>
								Портативна
								<FontAwesomeIcon :icon="faBriefcase"/>
							</button>
						</div>

						<div class="coordinates">
							<small>Широта</small><br/>
							<input 
								class="text-box"
								type="number" 
								step=".000001" 
								v-model="stationData.latitude"
								placeholder="Довгота"
								style="margin-bottom: 0.5em;"
								:class="{ invalid: !isLatValid(stationData.latitude) }"
							>
							<small>Довгота</small><br/>
							<input
								class="text-box"
								type="number" 
								step=".000001" 
								v-model="stationData.longitude"
								placeholder="Широта"
								style="margin-bottom: 0.5em;"
								:class="{ invalid: !isLonValid(stationData.longitude) }"
							>
						</div>
						<small>Висота встановлення (метрів)</small><br/>
						<input
							class="text-box"
							type="number" 
							step=".1"
							v-model="stationData.installation_altitude"
							placeholder="Висота встановлення"
							:class="{ invalid: !isAltValid(stationData.installation_altitude) }"
						>
						<div class="locationSelectorContainer" style="margin-top: 0.5em;">
							<LocationSelector 
								@selected="onLocationSelected"
								:location="stationLocation"
								:disabled="!editStation"
							/>
						</div>
					</div>
					<br/>
				</div>
				<div class="col-xs-12 col-sm-6">
					<h2>Показники</h2>
					<small>Станом на {{ stationData?.last_online }}</small>
					<br/><br/>
					<div v-if="stationData?.current_values?.length > 0" class="stationValuesList">
						<TransitionGroup>
							<StationValue v-for="v in stationData?.current_values" :key="v" :value="v" />
						</TransitionGroup>
					</div>
					<div v-else style="height: 10em;">
						<p>Тут чомусь нема даних... Схоже ваша станція давно не надсилала дані</p>
					</div>
				</div>
			</div>

			<br/><br/>
		</div>
	</div>
</template>

<script setup>
import { ref, defineProps, onMounted, computed, watch } from 'vue'
import { useRouter, onBeforeRouteLeave } from 'vue-router'

import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { faCircleDot } from '@fortawesome/free-solid-svg-icons'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { faFileZipper } from '@fortawesome/free-solid-svg-icons'
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { faHouseChimney } from '@fortawesome/free-solid-svg-icons'
import { faTreeCity } from '@fortawesome/free-solid-svg-icons'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'

import LoadingScreen from '@/components/LoadingScreen.vue'
import GradientBackground from '@/components/GradientBackground.vue'
import StationValue from '@/components/StationValue.vue'
import LocationSelector from '@/components/LocationSelector.vue';

import { getStationIcon } from '@/modules/StationIcon'
import { uaqiColors } from '@/modules/UAQI.js'
import { 
	isLatValid, 
	isLonValid, 
	isAltValid, 
	isStationNameValid,
	isStationValid
} from '@/modules/DataValidation.js';
import { SelectedStation } from '@/state/SelectedStation'

const props = defineProps({ id: String })

const router = useRouter()

const dataLoaded = ref(false)

const editStation = ref(false)

const stationDataElement = ref(null)

const sectionRealtime = ref(null)
const sectionStatistics = ref(null)
const sectionArchives = ref(null)

const stationLocation = computed(() => {
	return { lat: parseFloat(stationData.value.latitude), lng: parseFloat(stationData.value.longitude) }
})

function onLocationSelected(location) {
	stationData.value.latitude = location.lat
	stationData.value.longitude = location.lng
}

function goToSection(section) {
	section.scrollIntoView({ behavior: 'smooth' })
}

async function onStationSaveClick() {
	if (isStationValid(stationData.value) == false) {
		window.alert("Перевірте правильність введених даних")
		return
	}

	try {
		console.log("Saving USER STATION edit")
		console.log(stationData.value)
		await axios.patch("/api/v1/cabinet/station", stationData.value)
		editStation.value = false
	} catch (error) {
		console.error('Failed to load USER STATION: ', error)
		window.alert("Виникла незрозуміла помилка(\nПовідомте розробникам про це непорозуміння!")
	}
}

const stationData = ref(null)

const fetchStation = async () => {
	try {
		const response = await axios.get(`/api/v1/cabinet/station?id=${props.id}`)
		stationData.value = response.data
		dataLoaded.value = true

		console.log(stationData.value)
	} catch (error) {
		console.error('Error fetching STATION: ', error)
	}
}

onMounted(async () => {
	await fetchStation()

	// fetchAvg30()
	// fetchAvg48()

	SelectedStation.set(stationData)

	watch(editStation, () => {
		if (stationDataElement.value == null) return
	
		let inputs = stationDataElement.value.querySelectorAll('input, button');
		
		inputs.forEach(input => {
			input.disabled = !editStation.value;
		});
	}, { immediate: true })
})

onBeforeRouteLeave(() => {
	if (editStation.value) {
		const answer = window.confirm(
			'Схоже ви не зберегли Впевнені що хочете покинути сторінку?'
		)
		// cancel the navigation and stay on the same page
		if (!answer) return false
	}
})

</script>

<style scoped>
h2 {
	margin-bottom: 0.2em;
}

.topControls {
	display: flex;
	justify-content: space-between;
}

.topControls > div .btn {
	background-color: #101010;
	border: 1px solid v-bind(uaqiColors[stationData?.uaqi?.main]);
}

.topControls > div .btn:hover {
	background-color: v-bind(uaqiColors[stationData?.uaqi?.main]);
	color: white;
}

.navigationControls .btn + .btn {
	margin-left: 0.5em;
}

.navigationControls {
	display: flex;
}

@media only screen and (max-width: 520px) {
	.navigationControls > .btn > span {
		display: none;
	}
}

.topControls .btn > svg {
	margin-bottom: -0.5px;
}

.topControls .btn > span {
	margin-left: 0.5em;
}


/* .stationData .text-box:disabled {
	background-color: #00000000;
	color: var(--color-text);
	padding-left: 0px;
	font-weight: 700;

	padding-top: 0.1em;
	padding-bottom: 0.1em;
}

.stationData .text-box {
	transition: all 0.5s ease-in-out;

	font-weight: normal;

	margin-bottom: 1em;
} */

.stationData .invalid {
	background-color: #7c2f2f;
}

.stationData .btn:disabled {
	color: #808080;
}

.stationData .btn.active:disabled {
	color: black;
}

.stationValuesList > * {
	margin-bottom: 0.5em;
}

.btn.active {
	background-color: #ddd;
	color: black;
}

.locationSelectorContainer {
	width: 100%; 
	height: 20em;

	border-radius: 0.5em;

	overflow: hidden;
}

/* HIDE INPUT NUMBER UP/DOWN */
/* Chrome, Safari, Edge, Opera */
.coordinates input::-webkit-outer-spin-button,
.coordinates input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.coordinates input[type=number] {
  -moz-appearance: textfield;
}
</style>